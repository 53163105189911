
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase } from "@/core/api";

interface Filter {
  name: string;
  short_key: string;
  city: string;
  country_iso_2: string;
  status: string;
}

export default defineComponent({
  name: "group-filter-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      name: "",
      short_key: "",
      city: "",
      country_iso_2: "",
      status: "10",
    });

    const countryOptions = ref([]);
    const getCountryOptions = () => {
      ApiBase.getCountryData()
        .then(({ data }) => {
          if (data.code == 0) {
            countryOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      console.log("zou");
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getCountryOptions();
    });
    return {
      data,
      formRef,
      countryOptions,
      submit,
      handleReset,
    };
  },
});
